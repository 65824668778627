import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import { worker } from "./mocks/browser";

if (process.env["MOCKS_ENABLED"]) {
  worker.start();
}

if (__WEBPACK_DEV_SERVER__) {
  // eslint-disable-next-line global-require
  require("antd/dist/antd.less");
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
});

export const { bootstrap, mount, unmount } = lifecycles;
