import { ConfigProvider, notification } from "antd";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

import { Layout } from "./Layout";
import { client } from "./gql/gql-client";
import { ANTD_EXTERNAL_VERSION_LESS } from "./constants";

notification.config({
  top: 55,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`,
});

export default function Root() {
  return (
    <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
      <div className="batch-uploader-root-page">
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Layout />
          </BrowserRouter>
        </ApolloProvider>
      </div>
    </ConfigProvider>
  );
}
