import { Routes, Route } from "react-router-dom";
import React, { Suspense, lazy, ReactNode } from "react";

const ReturnToAuto1BatchUploader = lazy(
  () => import("../pages/ReturnToAuto1BatchUploader")
);
const InventorySourcingBatchUploader = lazy(
  () => import("../pages/InventorySourcingBatchUploader")
);
const AdPriceBatchUploader = lazy(
  () => import("../pages/AdPriceBatchUploader")
);

const TrackMigrationUploader = lazy(
  () => import("../pages/TrackMigrationUploader")
);

// PREPARE ROUTES
interface IRoute {
  route: string;
  element: ReactNode;
}

const routes: Array<IRoute> = [
  {
    route: "/en/return-to-auto1-batch-import",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ReturnToAuto1BatchUploader />
      </Suspense>
    ),
  },
  {
    route: "/en/inventory-sourcing-batch-import",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <InventorySourcingBatchUploader />
      </Suspense>
    ),
  },
  {
    route: "/en/ad-price-batch-import",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AdPriceBatchUploader />
      </Suspense>
    ),
  },
  {
    route: "/en/ad-price-track-import",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <TrackMigrationUploader />
      </Suspense>
    ),
  },
];

export const Layout: React.FC = () => {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes.map(({ route, element }) => (
            <Route key={route} path={route} element={element} />
          ))}
          <Route path="*" element="Page is not found" />
        </Routes>
      </Suspense>
    </div>
  );
};
