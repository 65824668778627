import PackageJson from "../package.json";

// antd-4.21.7
export const ANTD_EXTERNAL_VERSION = `antd-${PackageJson.dependencies.antd}`;
// antd-4-21-7 css classes
export const ANTD_EXTERNAL_VERSION_LESS = ANTD_EXTERNAL_VERSION.replace(
  /\./g,
  "-"
);
export const LOGGER_ENDPOINTS = {
  PROD: "https://retail-logger.prod.retail.auto1.cloud/v1/log",
  QA: "https://retail-logger.1.qa.retail.auto1.cloud/v1/log",
} as const;
